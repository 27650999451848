import { error, required as requiredValidation } from '@kaliber/forms/validation'

export const required = value => {
  const x = typeof value === 'string' ? value.trim() : value
  return requiredValidation(x)
}

export function fileSize(maxSize) {
  return x => x && (x.size === 0 || x.size > maxSize) && error('fileSize', maxSize)
}

export function fileExtension(extensions) {
  return x => {
    if (!x) return

    const [ext] = x.name.split('.').reverse()
    return (!ext || !extensions.some(x => ext.toLowerCase() === x)) &&
      error('fileExtension', extensions)
  }
}

export const number = x => x && Number(x) !== x && error('number')

export function checked(x) {
  const hasValue = Array.isArray(x) ? x.length : x
  return !hasValue && error('checked')
}

const linkedInUrlRegex = /^(https?:\/\/)?(www\.)?linkedin\.com\/.+$/
export const linkedInUrl = x => x && !linkedInUrlRegex.test(x) && error('linkedInUrl')

function createMatchEmailRegex() {
  /*
    Matches:
      abc
      abc.abc
      abc.abc.abc

    Does not match:
      .abc
      abc.
      abc..abc
  */
  const abc = /[^.@,?;&\][\s]+(\.[^.@,?;&\][\s]+)*/.source
  const domain = /[a-zA-Z]{2,}/.source
  return new RegExp(`^${abc}@${abc}\\.${domain}$`)
}

const emailRegex = createMatchEmailRegex()
const noEmailAccentsRegex = /^[^\u0080-\u00FF]+$/

export const noEmailAccents = x => x && !noEmailAccentsRegex.test(x) && error('noEmailAccents')
export const email = x => x && !emailRegex.test(x) && error('email')
